// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"Ubaq2sLoFitimBkt5INbV"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://44ee0606661407a5d139b79d55f6a645@o4506024949186560.ingest.sentry.io/4506024954888192',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true
    }),
    Sentry.browserTracingIntegration({
      shouldCreateSpanForRequest: (url) => {
        return !url.match(/\/health\/?$/)
      }
    })
  ],
  tracePropagationTargets: [
    'localhost',
    'localhost:3000',
    'localhost:8080',
    'app.dev.optaxe.com',
    'app.demo.optaxe.com',
    'app.optaxemtf.com',
    /^\//,
    /http:\/\/[a-z0-9-]+.backend.svc.cluster.local(:d+)?(\/[a-zA-Z0-9-_/]*)?/,
    'http://localhost:8080/v1/graphql',
    'ws://localhost:8080/v1beta1/relay',
    'https://dev-dutnl8-8.eu.auth0.com',
    'https://optaxemtf.eu.auth0.com',
    'https://dev-hasura.dev.optaxe.com/v1beta1/relay',
    'https://demo-hasura.dev.optaxe.com/v1beta1/relay',
    'https://hasura.api.optaxemtf.com/v1beta1/relay'
  ],
  enabled: process.env.NODE_ENV !== 'development'
})
